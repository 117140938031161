import "./contact-us.css";
import { useEffect ,useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link,withRouter} from "react-router-dom";
import { Button,Form,Row,Col,Spinner} from "react-bootstrap";

import { toast } from "react-toastify";



const ContactUsPage = () => {
  const dispatch = useDispatch();


  return (

    <>

<section class="hero">
        <div class="container">
            <div class="hero-inner">
    <div class="hero-copy">
                  <h1 class="hero-title mt-0">Contact Us</h1>
                  <p class="hero-paragraph"></p>
     </div>
    </div>
    </div>
  </section>
  
  <div className="container">

<Row>


<Col lg={6}>


<div className="align-self-center mb-4">

<div class="row block">


<Col lg={6}>
					<div class="address-block text-center mb-5">
						<div class="icon text-yellow">
							<i class="ti-mobile"></i>
						</div>
						<div class="details">
              <h5 class="h5 font-weight-bold">Sales Enquiry</h5>
              <p>Interested in any of our products?<br/>Talk to our experts today</p>
							<p class="text-yellow"><b>1800 890 4841</b></p>
              <p>MON-SAT (9AM-6PM)</p>
						</div>
					</div>

</Col>

<Col lg={6}>

          <div class="address-block text-center mb-5">
						<div class="icon text-yellow">
							<i class="ti-email"></i>
						</div>
						<div class="details">
              <h5 class="h5 font-weight-bold">Support Enquiry</h5>
              <p>Using any of our products and need help?<br/>Get in touch with customer support</p>
							<p class="text-yellow"><b>help@radhesolution.com</b></p>
              <p>MON-SAT (9AM-6PM)</p>
						</div>
					</div>

</Col>





<Col lg={6}>
					<div class="address-block text-center">
						<div class="icon text-yellow">
							<i class="ti-location-pin"></i>
						</div>
						<div class="details">
              <h5 class="h5 font-weight-bold">Registered Office</h5>
              <p>Shop No 5, 1st Floor Vallabh Vihar Park, Nr. Bhagirath bungalows &npsp; Super Bhajipaw Hirawadi Petrol Pump Vijay Park BRTS Ahmedabad - 382345</p>
							
						</div>
					</div>

</Col>


				</div>

        </div>


</Col>


 

  </Row>


  </div>

    </>

  );
};

export default ContactUsPage;
