import "./about-us.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link,withRouter} from "react-router-dom";


const AboutUsPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
  
    

  }, [dispatch]);

  return (

    <>

<section class="hero">
        <div class="container">
            <div class="hero-inner">
    <div class="hero-copy">
                  <h1 class="hero-title mt-0">About Us</h1>
                  <p class="hero-paragraph"></p>
     </div>
    </div>
    </div>
  </section>
  
 

  <section class="section about p-0">
	<div class="container">
		<div class="row">
			<div class="col-lg-6 align-self-center">
				<div class="content text-center text-lg-left">


        <h2>Print 2 PVC</h2>

Team of young enthusiastic minds with a goal to provide solutions to day to day problems at an affordable Cost. 

Product/ Technology Overview<br/>

<div class="pricing-tables-wrap">
<ul class="pricing-table-features list-reset text-xs">
                                    <li>
                                        <span>Web based solution to Print for PVC Card</span>
                                    </li>
                                    <li>
                                        <span>PVC Card Print can happen 24x 7 x 365</span>
                                    </li>
                                    <li>
                                        <span>E-Aadhaar Card</span>
                                    </li>
                                    <li>
                                        <span>E-UAN</span>
                                    </li>
                                    <li>
                                        <span>PM-JAY</span>
                                    </li>
                                    <li>
                                        <span>E-SHRAM</span>
                                    </li>
                                    <li>
                                        <span>UWIN</span>
                                    </li>
                                    <li>
                                        <span>ELECTION</span>
                                    </li>
                                    <li>
                                        <span>MAA VATSALYA</span>
                                    </li>
                                </ul>

                                </div>


<p><b>Printer Support</b></p>


Magicard, Fargo, Zebra, Evolis, Data Card, Smart, Paper Printer, All Epson PVC Tray Printers, Konica Minolta, Ciaat, OrphiCard, Matica Espresso, HiTi, DF350, Canon Tray Printers,




</div>
				
      </div>

		
		</div>
	</div>
</section>


    </>

  );
};

export default AboutUsPage;
