import "./refund-policy.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link,withRouter} from "react-router-dom";


const RefundPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
  
    

  }, [dispatch]);

  return (

    <>

<section class="hero">
        <div class="container">
            <div class="hero-inner">
    <div class="hero-copy">
                  <h1 class="hero-title mt-0">Refund Policy</h1>
                  <p class="hero-paragraph"></p>
     </div>
    </div>
    </div>
  </section>


  <div class="container">
  <p>We’re so convinced you’ll absolutely love our services, that we’re willing to offer a 7 day risk-free money back guarantee. If you are not satisfied with the service for any reason you can get a refund within 7 days of making a purchase.</p>
<h2>Contacting us</h2>
<p>If you have any questions, concerns, or complaints regarding this refund policy, we encourage you to contact us using the details below:</p>
<p><a target="_blank" rel="nofollow" href="https://pvc2print.com/contact-us">https://pvc2print.com/contact-us</a><br/>&#104;&#101;&#108;&#112;&#64;r&#97;&#100;h&#101;&#115;&#111;lu&#116;&#105;&#111;&#110;.&#99;&#111;&#109;<br/>Shop No 5, 1st Floor Vallabh Vihar Park, Nr. Bhagirath bungalows & Super Bhajipaw Hirawadi Petrol Pump Vijay Park BRTS Ahmedabad - 382345</p>
<p>This document was last updated on February 15, 2022</p>
      
</div>


</>


  );
};

export default RefundPage;
