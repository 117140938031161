import "./order.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link,withRouter} from "react-router-dom";
import axios from "axios";

const OrderPage = ({match,history}) => {

  const dispatch = useDispatch();


  useEffect(() => {
  },[dispatch]);

  useEffect(() => {
    
    if(match.params.id){
        //fetch orderData
        loadOrderData(match.params.id);
    }
   

  },[match]);


  const loadOrderData = async (id) => {

    console.log("loadOrderData - "+id);

    const config = {
		header: {
		  "Content-Type": "application/json",
		},
	  };

      try {

		//axios.post("/api/auth/signIn",fd,config);
		const { data } = await axios.post(
		  "/api/orders/razorpay-order",
          {
            razorpay_order_id:id
          },
		  config
		);

        console.log("loadOrderData response- "+data);

		if(data.status){
			//toast.error(data.message);
            displayRazorpay(id,data.data);
            console.log("razoypayKey - "+data.data.razoypayKey);
		}
		

	  } catch (error) {

      }

  }

    const razorpayVerification = async (razorpay_payment_id,razorpay_order_id,razorpay_signature) => {

        console.log("razorpayVerification start");
    const config = {
		header: {
		  "Content-Type": "application/json",
		},
	  };

    try {
		//axios.post("/api/auth/signIn",fd,config);
		const { data } = await axios.post(
		  "/api/orders/razorpay-verification",
		  { razorpay_payment_id,razorpay_order_id,razorpay_signature },
		  config
		);

        console.log("razorpayVerification response - "+data);

        if(data.status){
            history.push('/thank-you/')
        }else{
            console.log("verify payment failed");
        }

    }catch(e){

    }


  }



const __DEV__ = document.domain === "localhost";

  function loadScript(src){
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true)
      }
      script.onError = () => {
        resolve(false)
      }
      document.body.appendChild(script);
    })
  }

  async function displayRazorpay(orderId,order){
   
    console.log("displayRazorpay loading true");
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js")

    if(!res){
      console.log("Razorpay SDK fail to load. Are you online?")
      return;
    }

    const options = {
      "key": order.razoypayKey, // Enter the Key ID generated from the Dashboard
      "amount": order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      "currency": order.currency,
      "name": "pvc2print",
      "description": "Order #"+order.receipt,
      "image": "https://pvc2print.s3.ap-south-1.amazonaws.com/logo.png",
      "order_id": orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      
      "prefill": {
          "name": order.name,
          "email": order.email,
          "contact": order.phone
      },
      // "notes": {
      //     "address": "Razorpay Corporate Office"
      // },

      "handler": function (response){
        console.log(response.razorpay_payment_id);
        console.log(response.razorpay_order_id);
        console.log(response.razorpay_signature)
        
        razorpayVerification(response.razorpay_payment_id,response.razorpay_order_id,response.razorpay_signature);
      
    },
      "theme": {
          "color": "#3399cc"
      }
  };

  var razorpay = new window.Razorpay(options);

  razorpay.on('payment.failed', function (response){
    alert(response.error.code);
    alert(response.error.description);
    alert(response.error.source);
    alert(response.error.step);
    alert(response.error.reason);
    alert(response.error.metadata.order_id);
    alert(response.error.metadata.payment_id);
});

  razorpay.open();

  //setOrderId(order.receipt);
  //setProgress(false);

  }

  return (

    <>

<section class="hero">
        <div class="container">
            <div class="hero-inner">
    <div class="hero-copy">
                  <h1 class="hero-title mt-0">Order Payment Redirect</h1>
                  <p class="hero-paragraph"></p>
     </div>
    </div>
    </div>
  </section>
  
 

  <section class="section about p-0">
	<div class="container">
		<div class="row">
			

	
		</div>
	</div>
</section>


    </>

  );
};

export default OrderPage;
