import "./header.css";
import {useState,React } from "react";
import { Link,withRouter,useHistory} from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";


import { Button,Nav,Navbar,Card,Container,ListGroup} from "react-bootstrap";


const Header = (props) => {

  return (


    <header class="site-header">
            <div class="container">
                <div class="site-header-inner">
                    <div class="brand header-brand">
                        <h1 class="m-0">
							<Link to="/">
								<img class="header-logo-image" width="100px" src="https://pvc2print.s3.ap-south-1.amazonaws.com/logo.png" alt="Logo"/>
                            </Link>
                        </h1>
                    </div>
                </div>
            </div>
        </header>

  );
};

//export default Header;
export default withRouter(Header) 
