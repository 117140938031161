import "./home.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import { ReactSVG } from 'react-svg';


//import image1 from '../../images/feature-icon-01.svg'

const HomePage = () => {
  const dispatch = useDispatch();


  useEffect(() => {
   
   

  },[dispatch]);

  function loadScript(src){
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true)
      }
      script.onError = () => {
        resolve(false)
      }
      document.body.appendChild(script);
    })
  }


  return (
    <main>
    <section class="hero">
        <div class="container">
            <div class="hero-inner">
    <div class="hero-copy">
                  <h1 class="hero-title mt-0">Pvc2Print</h1>
                  <p class="hero-paragraph">print any card on pvc</p>
                  <div class="hero-cta">
                    <a class="button button-primary" href="https://play.google.com/store/apps/details?id=com.pvc2print">Android</a>
                    <a class="button button-primary" href="https://web.pvc2print.com">Web</a>
                   
                  </div>

  <div class="hero-cta" style={{marginTop:"5px"}}>
  <a class="button button-primary"  href="https://www.microsoft.com/en-in/p/pvc2print/9nw0tsbkftqt?rtc=1#activetab=pivot:overviewtab">Windows 10</a>
   <Link class="button" to="/contact-us">Get in touch</Link>

  </div>

    </div>
    <div class="hero-figure anime-element">
      <svg class="placeholder" width="528" height="396" viewBox="0 0 528 396">
        <rect width="528" height="396" style={{fill:'transparent'}} />
      </svg>
      <div class="hero-figure-box hero-figure-box-01" data-rotation="45deg"></div>
      <div class="hero-figure-box hero-figure-box-02" data-rotation="-45deg"></div>
      <div class="hero-figure-box hero-figure-box-03" data-rotation="0deg"></div>
      <div class="hero-figure-box hero-figure-box-04" data-rotation="-135deg"></div>
      <div class="hero-figure-box hero-figure-box-05"></div>
      <div class="hero-figure-box hero-figure-box-06"></div>
      <div class="hero-figure-box hero-figure-box-07"></div>
      <div class="hero-figure-box hero-figure-box-08" data-rotation="-22deg"></div>
      <div class="hero-figure-box hero-figure-box-09" data-rotation="-52deg"></div>
      <div class="hero-figure-box hero-figure-box-10" data-rotation="-50deg"></div>

      <img src="https://pvc2print.s3.ap-south-1.amazonaws.com/images/mock-1.png" style={{
          "z-index":"99999",
          "position": "absolute",
    "height": "500px",
    "top": "-15%",
    "left": "40%",
    "text-align":" center",
    "padding":" 10px",
          
          }} alt=""/>

    </div>
            </div>
        </div>
    </section>

    <section class="features section">
        <div class="container">
           
  <div class="features-inner section-inner has-bottom-divider">
                <div class="features-wrap">


                    <div class="feature text-center is-revealing">
                        <div class="feature-inner">
                            <div class="feature-icon">
                    <img src="https://pvc2print.s3.ap-south-1.amazonaws.com/images/feature-icon-01.svg" alt="Feature 01"/>
                            </div>
                            <h4 class="feature-title mt-24">Step 1</h4>
                            <p class="text-sm mb-0">Download app from play store.</p>
                        </div>
                    </div>


                    <div class="feature text-center is-revealing">
                        <div class="feature-inner">
                            <div class="feature-icon">
                    <img src="https://pvc2print.s3.ap-south-1.amazonaws.com/images/feature-icon-02.svg" alt="Feature 02"/>
                            </div>
                            <h4 class="feature-title mt-24">Step 2</h4>
                            <p class="text-sm mb-0">Sign up and get FREE 50 counter credit.</p>
                        </div>
                    </div>


                    <div class="feature text-center is-revealing">
                        <div class="feature-inner">
                            <div class="feature-icon">
                    <img src="https://pvc2print.s3.ap-south-1.amazonaws.com/images/feature-icon-03.svg" alt="Feature 03"/>
                            </div>
                            <h4 class="feature-title mt-24">Step 3</h4>
                            <p class="text-sm mb-0">Upload PDF fileand print the processed card information through card printer on plastic card.</p>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </section>

    <section class="pricing section">
        <div class="container-sm">
            <div class="pricing-inner section-inner">
                <div class="pricing-header text-center">
                    <h2 class="section-title mt-0">Download App</h2>
                    <p class="section-paragraph mb-0"></p>
                </div>
    <div class="pricing-tables-wrap">
                    <div class="pricing-table">
                        <div class="pricing-table-inner is-revealing">
                            <div class="pricing-table-main">
                                <div class="pricing-table-header pb-24">
                                    <div class="pricing-table-price">Plan Start With <span class="pricing-table-price-currency h2">₹</span><span class="pricing-table-price-amount h1">250</span><span class="text-xs"></span></div>
                                </div>
            <div class="pricing-table-features-title text-xs pt-24 pb-24">What you will get</div>
                                <ul class="pricing-table-features list-reset text-xs">
                                    <li>
                                        <span>Vaccine Card</span>
                                    </li>
                                    <li>
                                        <span>E-Pan Card</span>
                                    </li>
                                    <li>
                                        <span>E-Aadhaar Card</span>
                                    </li>
                                    <li>
                                        <span>E-UAN</span>
                                    </li>
                                    <li>
                                        <span>PM-JAY</span>
                                    </li>
                                    <li>
                                        <span>E-SHRAM</span>
                                    </li>
                                    <li>
                                        <span>UWIN</span>
                                    </li>
                                    <li>
                                        <span>ELECTION</span>
                                    </li>
                                    <li>
                                        <span>MAA VATSALYA</span>
                                    </li>
                                    <li>
                                        <span>AMD MORE</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="pricing-table-cta mb-8">
                                <Link class="button button-primary button-shadow button-block" to="/contact-us">Pre order now</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

<section class="cta section">
<div class="container">
  <div class="cta-inner section-inner">
    <h3 class="section-title mt-0">Still not convinced on buying?</h3>
    <div class="cta-cta">
      <Link class="button button-primary button-wide-mobile" to="/contact-us">Get in touch</Link>
    </div>
  </div>
</div>
</section>
</main>
  );
};

export default HomePage;
