import "./thank-you.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link,withRouter} from "react-router-dom";


const ThankYouPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
  
    

  }, [dispatch]);

  return (



    <section class="hero">
        <div class="container">
            <div class="hero-inner">
    <div class="hero-copy">
                  <h1 class="hero-title mt-0">Thank You!</h1>
                  <p class="hero-paragraph">Your order was completed successfully.<br/>Your counter is added in your account.</p>
     </div>
    </div>
    </div>
  </section>


    


  );
};

export default ThankYouPage;
