import "./App.css";
//import "slick-carousel/slick/slick.css";
//import "slick-carousel/slick/slick-theme.css";
import { useState ,React,useEffect} from "react";
import { BrowserRouter as Router, Switch, Route,useHistory } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Components
import Header from "./components/header/header";
import Footer from "./components/footer/footer";


// Pages
import HomePage from "./screens/home/home";

import PrivacyPolicyPage from "./screens/privacy-policy/privacy-policy";
import TermsAndConditionsPage from "./screens/terms-and-conditions/terms-and-conditions";
import ContactUsPage from "./screens/contact-us/contact-us";
import AboutUsPage from "./screens/about-us/about-us";
import OrderPage from "./screens/order/order";
import ThankYouPage from "./screens/thank-you/thank-you";
import RefundPolicyPage from "./screens/refund-policy/refund-policy";


function App() {
 
  return (
    <Router>
      
      <Header/>
    
      <ToastContainer />
    
      <main className="app">

        <Switch>
          <Route exact path="/" component={HomePage} />
        
          <Route exact path="/refund-policy" component={RefundPolicyPage} />
          <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />
          <Route exact path="/terms-and-conditions" component={TermsAndConditionsPage} />
          <Route exact path="/contact-us" component={ContactUsPage} />
          <Route exact path="/about-us" component={AboutUsPage} />
          <Route exact path="/order/:id" component={OrderPage} />
          <Route exact path="/thank-you" component={ThankYouPage} />
        
          <Route path="*" component={HomePage} />
          <Route path="/*" component={HomePage} />
          <Route path="/:id" component={HomePage} />
          <Route path="/?fbclid=" component={HomePage} />

        </Switch>

    
       

      </main>
    
      
      <Footer/>
    </Router>
  );
}

export default App;
